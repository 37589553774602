export default [
  "modules/accordions",
  "modules/clock",
  //"modules/filtered-posts-list",
  //"modules/google-map",
  "modules/job-single",
  "modules/lottie",
  //"mouse-hover-progress",
  "modules/newsletter",
  "modules/pb-row-advantages",
  "modules/pb-row-comparison-medias",
  "modules/pb-row-featured-posts",
  "modules/pb-row-hero",
  "modules/pb-row-medias",
  //"modules/pb-row-oembed",
  "modules/pb-row-posters",
  "modules/pb-row-scrolling-animated-text",
  //"modules/pb-row-tabs",
  "modules/pb-row-testimonials",
  "modules/pb-row-upcoming-projects",
  //"modules/pb-row-wrapper-reveal",
  "modules/post-archives",
  //"modules/prefer-color-scheme-btn",
  "modules/project-archives",
  "modules/project-instagram-grid",
  "modules/project-medias-viewer-grid",
  "modules/project-preview",
  "modules/project-social-posts",
  "modules/project-video-player",
  //"modules/scrolling-images-sequence",
  "modules/service-archives",
  "modules/sharing",
  //"modules/svg-path-length",
  "modules/text-ticker",
  "modules/typeform-button",
  "modules/video",
  //"modules/video-previewer"
];
