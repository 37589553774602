import Splitting from "splitting";

import { shuffle } from "@utils/array";
import { $, body } from "@utils/dom";
import { on, off } from "@utils/listener";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.label = $('.site-loader__label', this.el);

    this._chars = shuffle( Splitting({ target: this.label, by: 'chars' })[0].chars );
    this._index = 0;
    this._length = this._chars.length;
    this._previousChar = null;
    this._tick = null;
    this._resolve = null;

    this._onReadyCompleted = this._onReadyCompleted.bind(this);
    this._onInterval = this._onInterval.bind(this);

    // update label repeatedly
    if( !this._tick ) this._tick = setInterval(this._onInterval, 60);
  }

  loaded() {
    moduleDelays(350, 200);
  }
  ready() {
    return new Promise(resolve => {
      this._resolve = resolve;
      
      on(this.el, 'transitionend', this._onReadyCompleted);
      this.el.classList.add('--animate-out');
    });
  }

  _destroy() {
    if( this._tick ) clearInterval(this._tick);

    this.el = null;
    this.label = null;

    this._chars = null;
    this._index = null;
    this._length = null;
    this._previousChar = null;
    this._tick = null;
    this._resolve = null;

    this._onReadyCompleted = null;
    this._onInterval = null;
  }

  _onReadyCompleted(event) {
    if( event.target !== this.el ) return;

    off(this.el, 'transitionend', this._onReadyCompleted);

    // remove from DOM when completed
    if( this.el ) this.el.remove();

    // add class on body when transition is ready
    body.classList.add("--js-ready");

    // resolve transition
    this._resolve();
    this._destroy();
  }
  _onInterval() {
    // show previous character
    if( this._previousChar ) this._previousChar.style.removeProperty('visibility');

    // hide current character
    this._previousChar = this._chars[this._index];
    this._previousChar.style.visibility = 'hidden';

    // go to next character
    this._index++;

    // return to first character if we are at end
    if( this._index >= this._length ) {
      this._index = 0;
      this._chars = shuffle(this._chars);
    }
  }
}

export default SiteLoader;
