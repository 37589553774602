/**
* @core/windmill.buttons
* <br><br>
* ## Windmill Buttons.
*
* - Prepare buttons for special rollover effect.
*
* @module windmill
* @preferred
*/

import ButtonGlitchEffect from "@components/ButtonGlitchEffect";
import { $$ } from "@utils/dom";

const SELECTOR = "[data-button-glitch-effect]:not(.pointer-events-none)";

export class WindmillButtons {  
  constructor() {
    this.buttons = null;
  }

  /**
  * Plugin installation.
  */
  install(windmill) {
    windmill.on('ready', this._initButtons, this);
    windmill.on('enter', this._initButtons, this);
    windmill.on('exited', this._destroyButtons, this);
  }

  _initButtons({ current, next }) {
    this.buttons = [ ...$$(SELECTOR, next.container || current.container) ].map(btn => new ButtonGlitchEffect(btn));
  }
  _destroyButtons() {
    if( this.buttons ) this.buttons.forEach(btn => btn.destroy());
    this.buttons = null;
  }
}

export default WindmillButtons;
