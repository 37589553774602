import anime from "animejs";

import { $, $$ } from "@utils/dom";
import { moduleDelays } from "./utils";
import { getCurrentlocale } from "@core/locales"

const SELECTOR = "[data-site-transition]";

class SiteTransition {
  constructor() {
    this.el = $(SELECTOR);
    this._alternate_hreflang = null;
    this._alternate_hreflocale = null;
  }


  exit() {
    this.el.classList.remove("pointer-events-none");

    return new Promise((resolve) => {
      anime({
        targets: this.el,
        opacity: [0, 1],
        delay: 150,
        duration: 450,
        easing: "linear",
        complete: () => resolve()
      });

      this.el.classList.remove("visibility-hidden");
    });
  }

  entering() {
    moduleDelays(350, 300);
  }

  entering({ next }) {
    //
    // get the alternate link for site-nav, important note: this works only for 2 languages
    //
    const parser = new DOMParser();
    const source = parser.parseFromString(next.html, "text/html");
    const alternateHreflangElement = $(`link[rel='alternate'][hreflang]:not([hreflang='${getCurrentlocale()}'])`, source);
    if(alternateHreflangElement) {
      this._alternate_hreflang = alternateHreflangElement.getAttribute('href');
      this._alternate_hreflocale = alternateHreflangElement.getAttribute('hreflang')
    }
  }

  enter() {
    return new Promise((resolve) => {
      anime({
        targets: this.el,
        opacity: [1, 0],
        duration: 300,
        easing: "linear",
        complete: () => {
          this.el.classList.add("visibility-hidden", "pointer-events-none");
          resolve();
        }
      });
    });
  }

  entered() {
    // find all lang-item elements
    const languageItemElements = $$(`a[lang*='${this._alternate_hreflocale}']`);

    // change href values to the alternate hreflang
    if(this._alternate_hreflang && languageItemElements) {
      [...languageItemElements].forEach((element) => { element.setAttribute('href', this._alternate_hreflang) });
    }
  }
}

export default SiteTransition;
